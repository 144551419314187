import { Page } from 'src/app/core/model/page';
import { Bay } from './../model/bay';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/service/base.service';
import { environment } from 'src/environments/environment';
import { AdapterGarageBays } from '../model/adaptergarage';

@Injectable({
  providedIn: 'root'
})
export class BayService extends BaseService<Bay> {
  
  

  constructor(http: HttpClient) {
    super(http);
    this.path = "bay"
  }


  saveList(bayList, garage) {
    let adapter:AdapterGarageBays = new  AdapterGarageBays();
    adapter.bays = bayList;
    adapter.garage = garage;
    return this.http.post(environment.url+this.path+"/list/save",adapter);
  }


  saveList2(bayList) {
    
    return this.http.post<void>(environment.url+this.path+"/list/save",bayList);
  }


  findByGarage(garage){
    console.log()
    return this.http.post<any>(environment.url+this.path+"/list/bay/garage",garage);
  }


  createFile(){

    return this.http.get(environment.url+this.path+"/download/",{responseType : 'blob'});
  
  }

  terminateActivity(bay){
    return this.http.post(environment.url+this.path+"/terminate",bay);
  }

  getBaysByUser(){
    return this.http.get<any>(environment.url+this.path+"/list/byuser");
  }

}
