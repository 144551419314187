import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BayComponent } from './bay/bay.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatButtonModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material/material.module';
import { ActivityComponent } from './activity/activity.component';
import { LayoutComponent } from './layout/layout.component';
import { StateComponent } from './state/state.component';
import { PieceComponent } from './piece/piece.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TrafficLightComponent } from './traffic-light/traffic-light.component';
import { CoreModule } from '../core/core.module';
import { IndicadorSpeedComponent } from './indicador-speed/indicador-speed.component';
import { NumberIndicatorsComponent } from './number-indicators/number-indicators.component';
import { IndicatorMasterComponent } from './indicator-master/indicator-master.component';



@NgModule({
  declarations: [BayComponent, ActivityComponent, LayoutComponent, StateComponent, PieceComponent, TrafficLightComponent, IndicadorSpeedComponent, NumberIndicatorsComponent, IndicatorMasterComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    MaterialModule,
    ColorPickerModule,
    CoreModule
    
  ]
})
export class DashBoardModule { }
