import Swal from 'sweetalert2'

import { Activity } from './../model/activity';
import { ListDTO } from 'src/app/core/model/listDTO';
import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { GarageService } from '../service/garage.service';
import { Garage } from '../model/garage';
import { MessageService } from 'src/app/core/service/message.service';

import { BayService } from '../service/bay.service';
import { Bay } from '../model/bay';
import { ActivityService } from '../service/activity.service';
import { ComponentService } from '../service/component.service';
import { ComponentModel } from '../model/componentModel';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivityState } from '../model/activityState';
import { ActivityStateService } from '../service/activity-state.service';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityComponent implements OnInit {

  garages: Array<Garage>;


  activity: Activity;
  activities: Array<Activity>;

  bays: Array<Bay>;
  baySelected;
  stateSelected;
  componentSelected;
  states: Array<ActivityState>;

  textoListar = "EN PROCESO"

  garageSelected;

  editing = false;

  components: Array<ComponentModel>;

  onFilter;
  tittlesFilter = ['OT', 'TERMINADOS', 'ASIGNADOS'];
  attributesFilter = ["dto.ot", "deleted", 'assigned',"component", "state", "bay" ];
  typeFilters = ['text', 'deleted', 'deleted','object' ];



  arrayResults: Array<any>
  arrayObject: Array<any>;

  initialized = 0;

  constructor(private garageService: GarageService, private messageHandler: MessageService,
    private bayService: BayService, private activityService: ActivityService,
    private componentService: ComponentService, private stateService: ActivityStateService) { }

  ngOnInit() {
    this.garages = new Array<Garage>();
    this.listGarage();
    this.activity = new Activity();
    this.activities = new Array<Activity>();
    this.bays = new Array<Bay>();
    this.states = new Array<ActivityState>();
    this.components = new Array<ComponentModel>();
    this.listComponents();
    this.listStates();
    this.listActivities();
    this.listBaysByUser();
    this.arrayObject = new Array<any>();
    this.arrayResults = new Array<any>();
    this.onFilter = new ListDTO(new Activity())
    this.onFilter.deleted = false;
  }


  listGarage() {
    this.garageService.list({ page: 0, size: 300 }).subscribe(data => {

      if (data) {
        this.garages = data.content
      } else {
        this.garages = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }


  listBaysByGarage(event) {
    this.activity.garageId = this.garageSelected.id;
    this.bayService.findByGarage(this.garageSelected).subscribe(data => {
      if (data) {

        this.bays = data
      } else {
        this.bays = [];
      }
    })
  }


  isFirstTime = true;
  listBaysByUser() {
    this.bayService.getBaysByUser().subscribe(data => {
      if (data) {

        this.bays = data
        this.putNameToFilter();
        this.initialized++;
        console.log(this.initialized," bays")
        if(this.isFirstTime == true){
          this.arrayObject.push(this.bays);
          this.tittlesFilter.push('BAHIA');
          this.arrayResults.push("-1")
          this.isFirstTime = false;
        }
        
      } else {
        this.bays = [];
      }
    },error=>{
      console.log(this.initialized,"fallo", "bays")
      this.messageHandler.errorHandler(error)
    })
  }

  putNameToFilter() {
    this.bays.forEach(element => {
      element.name = element.bayNumber;
    })
  }



  saveActivity() {
    let msn = "";
    if (this.editing == false) {
      msn = this.valid();

    }

    if ("" == msn) {


      /*    if(this.garageSelected){
            this.activity.garageId = this.garageSelected.id;
            this.activity.garageName = this.garageSelected.name;
          }*/


      if (this.baySelected) {
        console.log(this.activity, "actividad", this.editing)
        if (this.editing == true && this.activity.bayId) {
          console.log("entrando")
          this.activity.oldBay = this.activity.bayId;
        }
        this.activity.bayNumber = this.baySelected.id;
        this.activity.bayId = this.baySelected.id;
      }

      if (this.stateSelected) {
        this.activity.stateName = this.stateSelected.name;
        this.activity.color = this.stateSelected.color;
        this.activity.activityStateCode = this.stateSelected.code;
      }

      if (this.componentSelected) {
        this.activity.componentName = this.componentSelected.name;
        this.activity.componentCode = this.componentSelected.code;
      }



      this.activityService.save(this.activity).subscribe(data => {
        if (data) {
          this.messageHandler.commonMessage("Guardado exitosamente", "success");
          this.listActivities();
          this.listBaysByUser();
          this.clear();
        }
      }, error => {
        this.messageHandler.errorHandler(error);
      })
    } else {
      this.messageHandler.commonMessage(msn, "error");
    }

  }

  listActivities(dto = { page: 0, size: 3000, deleted: false }) {
    console.log(dto,"a filtrar");
    this.activityService.list(dto).subscribe(data => {
      this.onFilter = new ListDTO(new Activity());
      if (data) {
        this.activities = data.content;
        console.log(data,"bahias retornadas")
        this.constructDataSource();
      } else {
        this.activities = [];
      }
      this.clear();
    }, error => {
      this.clear();
      this.messageHandler.errorHandler(error);
    })
  }

  valid() {
    let msn = "";



    if (!this.stateSelected)
      msn += "Debe seleccionar un un estado;";
    return msn;
  }



  clear() {
    this.activity = new Activity();
    this.garageSelected = null;
    this.baySelected = null;
    this.stateSelected = null;
    this.componentSelected = null;
    this.editing = false;
  }

  

  listComponents() {
    this.componentService.list({ page: 0, size: 3000 }).subscribe(data => {

      if (data) {
        this.components = data.content;
        this.initialized++;
        console.log(this.initialized, "components")
        this.arrayObject.push(this.components);
        this.tittlesFilter.push('COMPONENTE')
        
        this.arrayResults.push("-1")
      } else {
        this.components = [];
      }
    }, error => {
      console.log(this.initialized,"fallo componentes")
      this.messageHandler.errorHandler(error);
    })
  }

  listStates() {
    this.stateService.list({ page: 0, size: 300 }).subscribe(data => {

      if (data) {
        this.states = data.content;
        this.initialized++;
        console.log(this.initialized,"estados")
        this.arrayObject.push(this.states);
        this.tittlesFilter.push('ESTADO')
       
        this.arrayResults.push("-1")
      } else {
        this.states = []
      }
    }, error => {
      console.log(this.initialized,"fallo statdos")
      this.messageHandler.errorHandler(error);
    })
  }




  constructDataSource() {
    let dataSource: Array<ActivityInfo> = new Array<ActivityInfo>();
    let data;
    this.activities.forEach(element => {
      data = {
        id: element.id,
        bayId: element.bayId,
        garageId: element.garageId,
        workOrder: element.workOrder,
        componentCode: element.componentCode,
        bayNumber: element.bayNumber,
        garageName: element.garageName,
        description: element.description,

        activityStateCode: element.activityStateCode,
        color: element.color,
        componentName: element.componentName,
        stateName: element.stateName

      }
      dataSource.push(data);
    })

    this.dataSource = dataSource;


  }

  getValue(column, element) {
    if (column == "Id")
      return element['id']

    if (column == "Estado")
      return element['stateName']

    if (column == 'Componente')
      return element['componentName']

    if (column == "Orden de Trabajo") {
      let cell = element['workOrder'];
      if (cell)
        return cell;
      else
        return "No definida";
    }

  }

 

  edit(element) {
    this.activity = element;
    this.editing = true;
  }



  delete(element) {

    if (element && element.id) {
      Swal.fire({
        title: "Está seguro?",
        text: "Vas a terminar esta actividad",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: "No"
      })
        .then((willDelete) => {
          if (willDelete.value) {
            this.activityService.delete(element).subscribe(data => {
              if (data) {
                this.messageHandler.commonMessage("Se termino actividad exitosamente", "success")
                this.listActivities();
                this.listBaysByUser();
                this.clear();
              }
            }, error => {
              this.messageHandler.errorHandler(error);
            })
          }
        });
    } else {
      this.messageHandler.commonMessage("Debe especificar una actividad a eliminar", "info", "")
    }


  }





  dataSource = ELEMENT_DATA;
  columnsToDisplay = ['Estado', 'Componente', 'Orden de Trabajo'];
  expandedElement: ActivityInfo | null;



  downloadReport() {
    this.bayService.createFile().subscribe(data => {

      saveAs(data, 'bahias.xlsx')

    }, error => {
      this.messageHandler.errorHandler(error)
    });
  }


  getSate(item: Bay) {
    if (item.stateString) {
      return item.stateString;
    } else {
      return item.bayState;
    }
  }


  filter(event) {

    let dto = new ListDTO(new Activity())

    let activityFilter = new Activity();

    if (event.BAHIA) {
      let bay = JSON.parse(event.BAHIA);

      activityFilter.bayId = bay.id
      activityFilter.bayNumber = bay.bayNumber;
      activityFilter.garageId = bay.garageId;
      activityFilter.garageName = bay.garageName;
    }

    if (event.COMPONENTE) {

      let component = JSON.parse(event.COMPONENTE);

      activityFilter.componentCode = component.code;
      activityFilter.componentName = component.name;
    }

    if (event.ESTADO) {

      let state = JSON.parse(event.ESTADO)

      activityFilter.stateName = state.name;
      activityFilter.activityStateCode = state.code
    }

    if (event.principal.dto.ot) {
      activityFilter.workOrder = event.principal.dto.ot;
    }

    console.log(event,"esto es")
    if (event.principal.assigned) {
      dto.assigned = event.principal.assigned;
    }

    if (event.principal.deleted) {
      dto.deleted = event.principal.deleted;
    }

    console.log(dto,"aqui esta tu dto")

    dto.dto = activityFilter;
    console.log(dto);
    this.listActivities(dto);

  }


}


export interface ActivityInfo {
  id;
  description;
  bayId;
  garageId;
  workOrder;
  componentCode;
  bayNumber;
  garageName;
  activityStateCode;
  color;
  componentName;
  stateName;
}



const ELEMENT_DATA: ActivityInfo[] = [];

