import { ActivityService } from './../service/activity.service';
import { Activity } from './../model/activity';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GarageService } from '../service/garage.service';
import { MessageService } from 'src/app/core/service/message.service';
import { BayService } from '../service/bay.service';
import { Garage } from '../model/garage';
import { Bay } from '../model/bay';
import { ActivityStateService } from '../service/activity-state.service';
import { ActivityState } from '../model/activityState';
import { WorkOrderService } from 'src/app/core/service/work-order.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  @ViewChild('btnCloseModal',{read: ElementRef,static:false}) set inputTag2(input: any){
    if(!input){
      return
    }else{
      this.closeModal = input;
    }
  } 
  closeModal;


  @ViewChild('btnCloseModal2',{read: ElementRef,static:false}) set inputTag3(input: any){
    if(!input){
      return
    }else{
      this.closeModal = input;
    }
  } 
  btnCloseModal2;


  @ViewChild('btnAditionalInfo',{read: ElementRef,static:false}) set inputTag(input: any){
    if(!input){
      return
    }else{
      this.btnAditionalInfo = input;
    }
  } 

  btnAditionalInfo;

  garages: Array<Garage>;
  bays: Array<Bay>
  garage: Garage
  imgURL;
  firsTime = true;
  interval;
  states: Array<ActivityState>;
  infoBay: Bay;
  changing = false;

  editingPositionBay = false;
  reassignActivity;

  constructor(private garageService: GarageService, private messageHandler: MessageService,
    private bayService: BayService, private activityState: ActivityStateService,
     private workOrderService: WorkOrderService, private activityService: ActivityService) { }

  ngOnInit() {
    this.garages = new Array<Garage>();
    this.bays = new Array<Bay>();
    this.listGarage();
    this.states = new Array<ActivityState>();
    window.clearInterval();
    this.listActivityState();
    this.infoBay = new Bay();
  }


  getLayoutByGarage(garage) {
    this.garage = garage;
    this.bayService.findByGarage(garage).subscribe(data => {

      if (data) {
        this.bays = data;
        this.imgURL = this.garage.urlLayout;
        if (this.firsTime == true) {
          this.listAgain()
        }

      } else {
        this.bays = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error)
    })
  }

  listGarage() {
    this.garageService.list({ page: 0, size: 3000 }).subscribe(data => {

      if (data) {
        this.garages = data.content;
      } else {
        this.garages = [];
      }

    }, err => {
      this.messageHandler.errorHandler(err);
    })
  }

  giveStyle(item) {

    return JSON.parse(item);
  }

  getState(item: Bay) {
    if (item.bayState == "ON_FREE") {
      return true;
    } else {
      return false
    }
  }

  listAgain() {
    let ctx = this;

    this.interval = setInterval(() => {
      ctx.getLayoutByGarage(ctx.garage);
      ctx.firsTime = false;
    }, 3000);
  }

  goBack() {
    window.clearInterval(this.interval);

    this.firsTime = true;
    this.listGarage();
    this.imgURL = null;
    this.garage = null;
    this.bays = [];
  }


  giveStyleWithColor(style, color) {
    let jsonStyle = JSON.parse(style);
    let all = { top: jsonStyle.top, left: jsonStyle.left, background: "#" + color }

    return all;
  }

  listActivityState() {
    this.activityState.list({ page: 0, size: 300 }).subscribe(data => {
      if (data) {
        this.states = data.content;
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  getStyleState(item: ActivityState) {
    let style = { background: '#' + item.color }
    return style;
  }


  getInfoBay(item) {
    this.infoBay = item;
    
  }


  workOrderAditionalInfo;
  showAdditionalInfo(item) {
    if (item.workOrder) {
      this.workOrderService.findbyOt(item.workOrder).subscribe(data => {
        if (data) {
          this.workOrderAditionalInfo = data;

        }
      }, error => {
          this.workOrderAditionalInfo = null;
      })
    }

  }

  terminateActivity(bay = this.infoBay){
      this.bayService.terminateActivity(bay).subscribe(data=>{
          this.closeModal.nativeElement.click();
      },error=>{
        this.messageHandler.errorHandler(error);
      })
  }

  isforTerminate(){
    return (this.infoBay && this.infoBay.activities && this.infoBay.activities.length == 1)
  }

  changeEditing(){
    this.editingPositionBay = true;
    this.closeModal.nativeElement.click();
   
  }



  onClickImgBay(event){

    


    let percentY = (event.layerY/event.target.height)*100;
    let percentX = (event.layerX/event.target.width)*100;

    
    let point3 = '{"top": "'+percentY+'%", "left": "'+percentX+'%"}'

    this.infoBay.style =  point3;
    this.updateBay();
    

  }

  updateBay(){
    this.bayService.save(this.infoBay).subscribe(data=>{
      if(data){
        this.editingPositionBay = false
        this.getLayoutByGarage(this.garage);
        this.messageHandler.commonMessage("Bahía actualizada correctamente","success","Actualizado")
      }
    })
  }


  baySelected;
  reassign(activity){
    console.log(activity,"activity")
    this.reassignActivity = activity;
    window.clearInterval(this.interval);
    
  }

  reassignBay(){
    if(this.baySelected){
      this.reassignActivity.oldBay = this.infoBay.id;
      this.reassignActivity.bayNumber = this.baySelected.id;
      this.reassignActivity.bayId = this.baySelected.id;

      this.activityService.save(this.reassignActivity).subscribe(data=>{
        this.messageHandler.commonMessage("Actividad Reasignada correctamente","success","Reasignación");
        this.closeModal.nativeElement.click();
        this.reassignActivity = null;
        this.listAgain();
        
      })
    }else{
      this.messageHandler.commonMessage("Debe especificar una nueva bahía para reasignar","info","")
    }
  }

  getSate(item: Bay) {
    if (item.stateString) {
      return item.stateString;
    } else {
      return item.bayState;
    }
  }

 
  giveMeName(string:string, max= 15){
    if(string.length>15)
      return string.substring(0,max)+"...";
    else
      return string;
  }


}
