import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, ChartLegendOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-bar-graphic',
  templateUrl: './bar-graphic.component.html',
  styleUrls: ['./bar-graphic.component.css']
})
export class BarGraphicComponent implements OnInit {


  @Input() public lengendOption: ChartLegendOptions = {
    align: 'center',
    position: 'top'

  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
       ticks: { maxTicksLimit:5, padding: 5}
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },

    },
    legend: this.lengendOption,
    layout: { padding: { top: 20 } }

  };
  @Input() public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  @Input() public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];

  constructor() { }

  ngOnInit() {
    this.barChartOptions.legend = this.lengendOption;
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  
  }





}
