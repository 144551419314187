import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpInteceptor implements HttpInterceptor {

    token;
    constructor() { }

    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
        if (!this.token) {
            this.token = localStorage.getItem('tokenBays');
        } 
        if(!this.token){
            this.token = '';
        }
        let tokenizetReq = req.clone({
            setHeaders: {
                Authorization: this.token
            }
        });

        return next.handle(tokenizetReq);
    }
}
