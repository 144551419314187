import { UserDateAudit } from './user-date-audit';
export class User extends UserDateAudit {
   
    id;
    name;
    email;
    password;
    role;
    userIdentification;
    attemps;
     lastChangePaswword;
    apps;
    garage;
    forceChangePassword;
    
}