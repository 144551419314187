export class Indicator{
    id;
	indicatorId;
	indicatorName;
	indicatorGroup;
	indicatorSubGroup;
	indicatorZone;
	valueNum;
	valueStr;
	limUpper;
	limLower;
	interval;
	format;
	graficProcedure;
	graficType;
    period ;
	year;
	indicatorProcedure;
	description;
	monthly;
	indicatorClassImplementation
	titleMonthly
}