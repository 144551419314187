import { Component, OnInit } from '@angular/core';
import { ComponentModel } from '../model/componentModel';
import { ComponentService } from '../service/component.service';
import { MessageService } from 'src/app/core/service/message.service';
import { element } from 'protractor';

@Component({
  selector: 'app-piece',
  templateUrl: './piece.component.html',
  styleUrls: ['./piece.component.css']
})
export class PieceComponent implements OnInit {

  component: ComponentModel;
  components: Array<ComponentModel>;
  
  dataSource ;

  displayedColumns: string[] = ['code', 'name','edit'];

  constructor(private componentService: ComponentService, private messageHandler: MessageService) { }

  ngOnInit() {
    this.components = new Array<ComponentModel>();
    this.component = new ComponentModel();
    this.dataSource = new Array<ComponentModel>();
    this.listComponents();
  }

  listComponents() {
    this.componentService.list({ page: 0, size: 3000 }).subscribe(data => {
   
      if (data) {
        console.log(data)
        this.components = data.content
        this.buildData();
      } else {
        this.components = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  clear() {
    this.component = new ComponentModel();
  }

  save() {
    let msn = this.valid();

    if("" == msn ){
      this.componentService.save(this.component).subscribe(data=>{
        if(data){
          this.messageHandler.commonMessage("Componente almacenado correctamente","success");
          this.clear();
          this.listComponents();
          
        }
      }, error=>{
          this.messageHandler.errorHandler(error);
      })
    }else{
      this.messageHandler.commonMessage(msn,"info");
    }

  }

  delete(){
    if(this.component.id){
        this.componentService.delete(this.component).subscribe(data=>{
          
            this.clear();
            this.listComponents();
            
            this.messageHandler.commonMessage("Componente eliminado con exito","success");
          
        })
    }else{
      this.messageHandler.commonMessage("Debe especificar el componente a eliminar","info");
    }
  }

  valid() {
    let msn = "";
    if (!this.component.code) {
      msn += "Debe especificar un codigó;";
    }

    if (!this.component.name)
      msn += "Debe especificar un nombre;";

    return msn;
  }

  buildData(){
    let dataSource : Array<ComponentInterface> = new Array<ComponentInterface>() ;
    let data;
    this.components.forEach(element=>{
          data = {
            id: element.id,
            code: element.code,
            name: element.name
          }
         dataSource.push(data);
    })
    this.dataSource = dataSource;
    console.log("ey")
  }

  edit(element){
    this.component = element;
  }



}

  

export interface ComponentInterface {
  id;
  code;
  name;
}
