import { Indicator } from './../model/indicator';
import { BarGraphicModalComponent } from './../../core/bar-graphic-modal/bar-graphic-modal.component';
import { JustInfoDialogComponent } from './../../core/just-info-dialog/just-info-dialog.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { IndicatorResult } from '../model/indicator-result';
import { Garage } from '../model/garage';
import { GarageService } from '../service/garage.service';
import { IndicatorService } from '../service/indicator.service';
import { MessageService } from 'src/app/core/service/message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-number-indicators',
  templateUrl: './number-indicators.component.html',
  styleUrls: ['./number-indicators.component.css']
})
export class NumberIndicatorsComponent implements OnInit {

  indicators: Array<IndicatorResult>;

  garages: Array<Garage>;
  garageSelected;

  indicatorsData;

  constructor(private garageService: GarageService, private messageHandler: MessageService,
    private indicatorService: IndicatorService, public dialog: MatDialog) { }

  ngOnInit() {
    this.indicators = new Array<IndicatorResult>();
    this.listGarage()
    this.listIndicators();
    this.garageSelected =new Garage();
    this.garageSelected.name ="sin"
  }


  listGarage() {
    this.garageService.list({ page: 0, size: 300 }).subscribe(data => {
      
      if (data) {
        this.garages = data.content
      } else {
        this.garages = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }


  

 

  listIndicators() {
    this.indicatorService.getList({ page: 0, size: 3000, dto: { graficType: "SPEEDOMETER" } }).subscribe(data => {
   
      if (data) {
        this.indicatorsData = data.content;
        
      }

      this.listIndicatorsByGarageAll();

    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  listIndicatorsByGarageAll() {

    this.indicators = [];

    let date = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    date += ' 00:00:00'


    this.indicatorService.getAllIndicators({ date: date, garage: this.garageSelected, indicators: this.indicatorsData }).subscribe(data => {
      console.log(data,"asjdf")  
      this.indicators = data;

    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  showInfoOnDialog(indicator: IndicatorResult) {
    const DialogRef = this.dialog.open(JustInfoDialogComponent, {
      width: '80%',
      data: { tittle: "Descripción del" + indicator.name, info: indicator.description }
    })
  }

 showTendencyDialog(indicator) {
    let list = new Array<Indicator>();

    let indicatorMonthly = new Indicator();
    indicatorMonthly.graficType = "MONTHLYBAR";
    indicatorMonthly.indicatorProcedure = indicator.monthly;
    indicatorMonthly.indicatorName = indicator.response[0].name;

    if (this.garageSelected) {
      indicatorMonthly.indicatorZone = this.garageSelected.id;
    }

    list.push(indicatorMonthly);
    this.indicatorService.getIndicatorsMonthly(list).subscribe(data => {
      let labels = new Array<String>();
      let dataRecive = new Array<number>();
      if (data[0].response) {
        data[0].response.forEach(element => {
          labels.push(element.monthString);
          dataRecive.push(element.avg);

        });
      }
      const DialogTendencyRef = this.dialog.open(BarGraphicModalComponent, {
        width: '70%',
        data: { labels: labels, data: [{ data: dataRecive, label: "Mensual" }] }
      })

    }, error => {
      this.messageHandler.errorHandler(error);
    })

  }

}
