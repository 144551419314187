import { User } from './../model/user';
import { MessageService } from './../service/message.service';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.css']
})
export class ForceChangePasswordComponent implements OnInit {

  oldPassword;
  newPassword;
  confirmPassword
  user: User;

  constructor(private authService: AuthService, private messageHandler: MessageService) { }

  ngOnInit() {
    this.user = new User();
    this.user =  this.authService.getUser();
    if(!this.user ){
      let userMail = localStorage.getItem("mailBays");
      if(userMail){
        this.authService.find(userMail).subscribe(data=>{
          if(data){
            this.user = data;
          }
        },error=>{
          this.messageHandler.errorHandler(error);
        })
      }
     
    }
  }

  changePassword(){

    let msn = this.valid();
    if("" == msn ){
      console.log(this.newPassword, this.oldPassword, this.confirmPassword)
      this.authService.changePassword(this.user.email, this.newPassword, this.oldPassword).subscribe(data=>{
        this.messageHandler.commonMessage("Contraseña actualizada correctamente","success","");
        this.newPassword = "";
        this.confirmPassword = "";
        this.oldPassword = "";
      },error=>{
        this.messageHandler.errorHandler(error);
      })
    }else{
      this.messageHandler.commonMessage(msn,"info","")
    }
    
  }

  valid(){
    let msn = "";

    if(!this.oldPassword){
      msn += "Debe ingresar la contraseña anterior;"
    }
    if(!this.newPassword){
      msn += "Debe especificar la nueva contraseña;"
    }

    if(!this.confirmPassword || this.confirmPassword != this.newPassword){
      msn += "Las contraseñas deben coincidir;"
    }

    if(!this.user ){
      msn += "Usuario no definido";
      
    }

    console.log(this.oldPassword, this.user.password);
    return msn;
   
  }

}
