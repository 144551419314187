import { DialogData } from './../interface/dialog-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-just-info-dialog',
  templateUrl: './just-info-dialog.component.html',
  styleUrls: ['./just-info-dialog.component.css']
})
export class JustInfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JustInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

}
