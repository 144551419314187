import { Bay } from './../../dash-board/model/bay';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-show-bay',
  templateUrl: './show-bay.component.html',
  styleUrls: ['./show-bay.component.css']
})
export class ShowBayComponent implements OnInit {

  bay: Bay;
  constructor(
    public dialogRef: MatDialogRef<ShowBayComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
     this.bay = new Bay();
     this.bay.bayNumber = data.bay.bayNumber;
     this.bay.excludeBay = data.bay.excludeBay;
     
  }

  onNoClick(): void {
    this.dialogRef.close();


  }

  
  ngOnInit(): void {
    console.log(this.bay,"asdfasadsfasdfjja vkjsdfjdkjfkn  j f  asfkjasddf",this.data)
  }

  

}
