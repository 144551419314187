import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { firebaseConfig } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { 
    firebase.initializeApp(firebaseConfig);
  }

  public tareaCloudStorage(nombreArchivo: string, datos: any):any {
    
    let date = new Date();

      let name = date.getFullYear()+"_"+date.getMonth()+"_"+date.getDay()+"_"+date.getTime()+"/"+nombreArchivo;
      console.log(name,"nmnre")
     let ref = firebase.storage().ref("images/"+name)
     let task = ref.put(datos);
     return task;  
  }
}
