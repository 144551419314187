import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/service/base.service';
import { ActivityState } from '../model/activityState';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivityStateService extends BaseService<ActivityState> {

  constructor(http: HttpClient) {
    super(http);
    this.path = "activity/state";
   }
}
