
import { AdapterGarageBays } from './../model/adaptergarage';


import { MatDialog } from '@angular/material';
import { style } from '@angular/animations';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GarageService } from '../service/garage.service';
import { Garage } from '../model/garage';
import { MessageService } from 'src/app/core/service/message.service';
import { Bay } from '../model/bay';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BayService } from '../service/bay.service';
import { ShowBayComponent } from 'src/app/core/show-bay/show-bay.component';

@Component({
  selector: 'app-bay',
  templateUrl: './bay.component.html',
  styleUrls: ['./bay.component.css']
})
export class BayComponent implements OnInit, AfterViewInit {







  @ViewChild('btnAditionalInfo', { read: ElementRef, static: false }) set inputTag(input: any) {
    if (!input) {
      return
    } else {
      this.btnAditionalInfo = input;
    }
  }

  @ViewChild('btnCloseModal', { read: ElementRef, static: false }) set inputTag2(input: any) {
    if (!input) {
      return
    } else {
      this.btnCloseModal = input;
    }
  }

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;


  btnAditionalInfo;
  btnCloseModal;

  imgURL;
  addGarage = 0;
  garages: Array<Garage>;

  isDefiningBays = false;
  isLinear = true;




  bays = new Array<Bay>();
  bay: Bay;

  garage: Garage;



  constructor(private garageService: GarageService, private messageHandler: MessageService,
    private bayService: BayService, public dialog: MatDialog) {
    this.garages = new Array<Garage>();
    this.garage = new Garage();
    this.bay = new Bay();

  }

  ngOnInit() {
    this.listGarage();





  }

  ngAfterViewInit(): void {

  }


  onFileComplete(data: any) {
    this.imgURL = data;
    this.garage.urlLayout = data;

  }

  onClickImgBay(event) {
    if (this.isDefiningBays == true) {
      this.bay = new Bay();

      this.bay.garageName = this.garage.name;

      let percentY = (event.layerY / event.target.height) * 100;
      let percentX = (event.layerX / event.target.width) * 100;


      let point3 = '{"top": "' + percentY + '%", "left": "' + percentX + '%"}'
      this.bay.style = point3;


      this.btnAditionalInfo.nativeElement.click();
      this.isDefiningBays = false;
    }

  }

  giveStyle(item) {
    if (item)
      return JSON.parse(item);
  }

  putCursorPointer() {
    if (this.isDefiningBays)
      return { cursor: 'pointer' }
    else
      return { cursor: 'auto' }
  }

  listGarage() {
    this.garageService.list({ page: 0, size: 3000 }).subscribe(data => {

      if (data) {
        this.garages = data.content;
      } else {
        this.garages = [];
      }

    }, err => {
      this.messageHandler.errorHandler(err);
    })
  }


  defineBay() {
    this.isDefiningBays = true;
  }


  revert() {
    this.bays.pop();
  }

  saveBay() {
    let msn = false;
    if (this.bay.bayNumber) {
      if (this.bays.length > 0) {
        this.bays.forEach(element => {
          if (element.bayNumber == this.bay.bayNumber) {


            this.messageHandler.commonMessage("Nombre de bahía ya definido", "error");

            msn = true;
          }
        });
      }

      if (msn == false) {
        this.btnCloseModal.nativeElement.click();
        this.bays.push(this.bay)
      } else {
        return;
      }

    } else {
      this.messageHandler.commonMessage("Debe definir un numero de bahía valido", "error");
    }



  }

  restartBays() {
    this.bays = new Array<Bay>();
  }

  deletedLastOne() {
    this.bays.pop();
  }


  isFirstStepComplete() {

    if (!this.imgURL)
      return false;
    if (!this.garage.name)
      return false;

    if (!this.garage.city)
      return false


    return true
  }

  isSecondStepComplete() {
    return (this.bays && this.bays.length != 0)

  }

  giveMeName(string: string, max = 15) {
    if (string.length > 15)
      return string.substring(0, max) + "...";
    else
      return string;
  }


  saveGarage() {
    let adapter = new AdapterGarageBays();
    adapter.bays = this.bays;
    adapter.garage = this.garage;
    this.garageService.saveList(adapter).subscribe(data => {

      if (data) {
        this.messageHandler.commonMessage("Taller guardado con exito", "success", "");
        this.cancel();
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })

  }

  restart() {
    this.bays = new Array<Bay>();
    this.bay = new Bay();
    this.addGarage = 0;
    this.imgURL = null;
    this.listGarage();
  }


  newGarage() {
    this.addGarage = 1;
    this.bays = new Array<Bay>();
    this.bay = new Bay();
    this.imgURL = null;
  }


  deleteBay(garage) {
    this.garageService.delete(garage).subscribe(data => {

      this.messageHandler.commonMessage("Taller eliminado exitosamente", "success", "");
      this.listGarage();

    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  bayToMove;


  moveBayRequired(item, event) {
    this.bayToMove = item;
  }


  movingOnBay(event) {
    if (this.bayToMove && this.bayToMove != null) {
      this.bayToMove.style = this.defineCoordinate(event, "movingon");
    }
  }

  defineCoordinate(event, string) {
    let percentY = (event.layerY / event.target.height) * 100;
    let percentX = (event.layerX / event.target.width) * 100;


    let point3 = '{"top": "' + percentY + '%", "left": "' + percentX + '%"}'
    
    return point3;
  }

  finishMoveBay(event) {
    if (this.bayToMove) {
      if(event.target.height){
        this.bayToMove.style = this.defineCoordinate(event, "finishMoveBay");
      }
     
      this.bayToMove = null;
    }
    this.bayToMove = null;
  }

  showInfoBay(bay) {

    const bayBefore = new Bay();
    bayBefore.bayNumber = bay.bayNumber;
    bayBefore.excludeBay = bay.excludeBay;

    if (this.bayToMove) {
     // this.bayToMove.style = this.defineCoordinate(event, "finishMoveBay");
      this.bayToMove = null;
    }

    const dialog = this.dialog.open(ShowBayComponent, {

      data: { bay: bay },
    })

    dialog.afterClosed().subscribe(data => {
      if (data) {
        bay.excludeBay = data.excludeBay;
        if (bayBefore.bayNumber != data.bayNumber) {
          
         
          let msn = false;
          this.bays.forEach(element => {
            if (element.bayNumber == data.bayNumber) {


              this.messageHandler.commonMessage("Nombre de bahía ya definido", "error");
              msn = true;
              return;
            }
          });
         
          if(msn == false){
            bay.bayNumber = data.bayNumber

          }

        }
      }
    })

  }

  editBay(item) {

    this.addGarage = 1;
    this.imgURL = item.urlLayout
    this.garage = item;

    this.bayService.findByGarage(item).subscribe(data => {
      this.bays = data;
    }, error => {
      this.messageHandler.errorHandler(error);
    })

  }

  cancel() {
    this.addGarage = 0;

  }

}
