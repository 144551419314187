import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { WorkOrder } from '../model/workorder';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService extends BaseService<WorkOrder> {

  constructor(http: HttpClient) {
    super(http);
    this.path = "workorder";
   }


  saveFile(file){
    let form = new FormData();
    form.append('file',file);
   
    return this.http.post(environment.url+this.path+"/upload",form,{ headers: new HttpHeaders({ timeout: '360000' }) });
  }

  findbyOt(ot){
    return this.http.get<WorkOrder>(environment.url+this.path+"/find/ot/"+ot);
  }


}
