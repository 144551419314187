import { User } from './../model/user';
import { MessageService } from './../service/message.service';
import { AuthService } from './../service/auth.service';
import { messages } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  password;
  user;
  remember = false;
  showed = true;



  constructor(private auth: AuthService, private router: Router, private messageHandler: MessageService) { }

  ngOnInit() {
    let loggued = localStorage.getItem("logged");
    let remember = localStorage.getItem("rememberBays");
    let token = localStorage.getItem("tokenBays")

 
    
  
    if(remember && remember == "true"){
      
      if(loggued && loggued == "true" && token != null && token != ""){
        
        this.router.navigate(['home'])
      }else{
        this.user = localStorage.getItem('mailBays');
        this.password = localStorage.getItem("passwordBays");
        this.remember = true;

        
      }

    }else if(token && token != ""){
      
      this.showed = false;
      
     this.auth.logout();
    }else{
      localStorage.clear();
      this.router.navigate(['login']);
    }
    
  }

  login() {


    if (!this.password || !this.user) {
      Swal.fire(messages.errorTittle, 'Ingrese usuario y contraseña', 'error');
      return;
    }
    this.auth.signin({ usernameOrEmail: this.user, password: this.password }).subscribe(res => {
      
      if (res.accessToken) {
        if(this.user){
          this.auth.find(this.user).subscribe(user => {
            localStorage.setItem('tokenBays', res.accessToken);
            localStorage.setItem('loggedBays', "true");
            localStorage.setItem('userIdBays', "" + user.id);
            localStorage.setItem('mailBays',""+user.email)
            if (this.remember == true) {
              localStorage.setItem('usernameBays', "" + this.user);
              localStorage.setItem('passwordBays', "" + this.password);
              localStorage.setItem('rememberBays', "true");
            } else {
              localStorage.removeItem("usernameBays")
              localStorage.removeItem("rememberBays")
              localStorage.removeItem("passwordBays")
            }
            this.auth.setUser(user);
  
           
            this.router.navigate(['home']);
          
          });
        }
       
      }
    }, err => {
      this.messageHandler.errorHandler(err);
    }
    );
    this.router.navigate(['login']);
  }

 


  goToSismoit() {
    window.open("https://sismoit.com/");
  }
}

