import { environment } from './../../../environments/environment';

import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/service/base.service';
import { Garage } from '../model/garage';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GarageService  extends BaseService<Garage>{

  

  constructor(http: HttpClient) {
   
    super(http);
    this.path = "garage"
   }


   saveList(adapter){
     return this.http.post<any>(environment.url+this.path+"/save/with/bays",adapter);
   }

}
