import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';



@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

   //titulos de los filtros
   @Input() tittles  = [];
   //tipo de atributos que tien cuando presentan en este array un objeto es creado un seleccionable con cada uno de los objetos en el arrayObject
   @Input() typeAttribute = [];
   //los atributos del objeto itemFilter donde se almacenara la información digitada 
   @Input() attributes;
   //array de objetos si se requiere selleccionable
   @Input() arrayObject;
   //array de resultados de lo que se seleciona en los combo box creados para el array de objetos
   @Input() arrayResults= [];
   //item principal donde se guarda los resultados
   @Input() itemFilter;
   //item donde con el que se emite la informacion por evento recordar que el objeto debe tener el formato {deleted: false/true, ....dto:{objeto principal}}
   @Output() filter= new EventEmitter();
   @ViewChild('buttonClose',{read: ElementRef,static:false}) set tag(input: ElementRef|null){
     if(!input)return 
     else
     this.buttonClose = input;
   } buttonClose;
   object;
 
   constructor() { 
     
   }
 
   ngOnInit() {
      console.log("object",this.arrayObject)
   }
 
 
 
 
   //se contruye el objeto con la propiedad principal donde queda almacenado en formato {...,dto:{objeto principal}, si exitsten objetos se envian con el titulo asignado : objeto en string seleccionado}
   filterObject(){
     let textResult = '{'+'"principal":'+JSON.stringify(this.itemFilter);
 
    if(this.arrayObject){
      textResult +=',';
     for (let index = 0; index < this.arrayResults.length; index++) {
       textResult += '"'+this.tittles[(this.tittles.length-this.arrayObject.length)+(index)]+'":'+JSON.stringify(this.arrayResults[index])
       if(index < this.arrayResults.length-1){
         textResult +=  ','
       }else{
         textResult +=  '}'
       }
 
       
     }
    }else{
     textResult +=  '}'
    }
    
     console.log(textResult,"enviado")
     console.log(JSON.parse(textResult))
     this.filter.emit(JSON.parse(textResult));
     this.buttonClose.nativeElement.click();
   }
 
   giveOnString(itemObject){
     return JSON.stringify(itemObject);
   }
 
   containsDTO(item:string){
     let s = item.includes("dto")
     return s;
   }
 
   giveAttribute(item:string){
     let result = item.split(".");
     if(result.length >1){
       return result[1];
     }
   }
 

}
