import { Component, OnInit } from '@angular/core';
import { ActivityState } from '../model/activityState';
import { ActivityStateService } from '../service/activity-state.service';
import { MessageService } from 'src/app/core/service/message.service';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

  state: ActivityState;
  states: Array<ActivityState>;
  color;
  
  dataSource ;

  displayedColumns: string[] = [ 'code', 'name','color','edit'];

  constructor(private stateService: ActivityStateService, private messageHandler: MessageService) { }

  ngOnInit() {
    this.states = new Array<ActivityState>();
    this.state = new ActivityState();
    this.dataSource = new Array<ActivityState>();
    this.listStates();
  }

  listStates() {
    this.stateService.list({ page: 0, size: 3000 }).subscribe(data => {
   
      if (data) {
        console.log(data)
        this.states = data.content
        this.buildData();
      } else {
        this.states = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  clear() {
    this.state = new ActivityState();
  }

  save() {
    let msn = this.valid() ;

    if("" == msn ){
      this.state.color = this.state.color.replace("#","");
      this.stateService.save(this.state).subscribe(data=>{
        if(data){
          this.messageHandler.commonMessage("Estado almacenado correctamente","success");
          this.clear();
          this.listStates();
          
        }
      }, error=>{
          this.messageHandler.errorHandler(error);
      })
    }else{
      this.messageHandler.commonMessage(msn,"info");
    }

  }

  delete(){
    if(this.state.id){
        this.stateService.delete(this.state).subscribe(data=>{
          if(data){
            this.messageHandler.commonMessage("Estado eliminado con exito","success");
          }
          this.listStates();
          this.clear()
        })
    }else{
      this.messageHandler.commonMessage("Debe especificar el estado a eliminar","info");
    }
  }

  valid() {
    let msn = "";
    console.log(this.state);
    if (!this.state.code) {
      msn += "Debe especificar un codigó;";
    }

    if (!this.state.name)
      msn += "Debe especificar un nombre;";

    if(!this.state.color)
      msn +="Debe especificar un color;";

    return msn;
  }

  buildData(){
    let dataSource : Array<StateInterface> = new Array<StateInterface>() ;
    let data;
    this.states.forEach(element=>{
          data = {
            id: element.id,
            code: element.code,
            name: element.name,
            color: element.color
          }
         dataSource.push(data);
    })
    this.dataSource = dataSource;
    
    console.log("ey",this.dataSource);
  }

  edit(element){
    this.state = element;
  }


}

  

export interface StateInterface {
  id;
  code;
  name;
  color;
}



