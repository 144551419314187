import { ShowBayComponent } from './show-bay/show-bay.component';


import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { FilterComponent } from './filter/filter.component';
import { LoadingComponent } from './loading/loading.component';
import { SpeedometerComponent } from './speedometer/speedometer.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NotFoundComponent } from './not-found/not-found.component';
import { CakeGraphicComponent } from './cake-graphic/cake-graphic.component'
import { ChartsModule } from 'ng2-charts';
import { UserComponent } from './user/user.component';
import { HomeComponent } from './home/home.component';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { JustInfoDialogComponent } from './just-info-dialog/just-info-dialog.component';
import { BarGraphicComponent } from './bar-graphic/bar-graphic.component';
import { BarGraphicModalComponent } from './bar-graphic-modal/bar-graphic-modal.component';
import { BarGraphicsModalComponent } from './bar-graphics-modal/bar-graphics-modal.component';



@NgModule({
  declarations: [LoginComponent, FilterComponent, LoadingComponent,
     SpeedometerComponent, NotFoundComponent, CakeGraphicComponent,
      UserComponent, HomeComponent, ForceChangePasswordComponent, JustInfoDialogComponent, 
      BarGraphicComponent, BarGraphicModalComponent, BarGraphicsModalComponent, ShowBayComponent],
  imports: [
    CommonModule, MaterialModule,FormsModule, GaugeChartModule,ChartsModule
  ],
  exports: [
    LoginComponent,JustInfoDialogComponent, BarGraphicModalComponent,BarGraphicComponent,BarGraphicsModalComponent,
    FilterComponent, MaterialModule, LoadingComponent, GaugeChartModule,SpeedometerComponent, CakeGraphicComponent,
    ShowBayComponent
  ],
  entryComponents:[LoadingComponent,JustInfoDialogComponent,BarGraphicModalComponent
    ,BarGraphicsModalComponent, ShowBayComponent],
    providers: [DecimalPipe]
})
export class CoreModule { }
