import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  public errorHandler(res){
    let msn = "";
    if(res.status && res.status == 404){
      this.commonMessage("Recurso no encontrado","error");
    }else if(res){
      if(res.error){
        if(res.error.errors){
          for(let errmsn of res.error.errors){
            msn += errmsn.defaultMessage;
          }
        }else if(res.error.message){
    
          msn = res.error.message;
        }else if(res.message){
          if(res.message.includes("Http failure response for http")){
            msn = "Error desconocido comuniquese con soporte técnico ";
          }else{
            msn = res.message
          }
        }else{
          msn = "error desconocido comuniquese con soporte tecnico";
        }
        if(msn.includes("not authorized")){
          msn = "Usuario y/o contraseña invalido";
        }
    
      }else{
        msn += "error desconocido comuniquese con soporte tecnico";
      }
      
      this.commonMessage(msn,"error");
    }
   
  }

  public commonMessage(message,type,tittle = message.errorTittle){

    let array = message.split(";");
    let messageToShow="";
    if(array.length>1){
      array.forEach(element => {
        messageToShow += "<br>*"+element;
      });
    }else{
      messageToShow = message;
    }
    

    
    
    Swal.fire(tittle,messageToShow,type);
  }
}
