import { User } from './core/model/user';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WorkOrderService } from './core/service/work-order.service';
import { MessageService } from './core/service/message.service';
import { MatDialog } from '@angular/material';
import { LoadingComponent } from './core/loading/loading.component';
import { AuthService } from './core/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  
  

  dialogRef;
  user: User;
  setTimeOut;

  constructor(private workOrderService: WorkOrderService, private messageHanlder: MessageService,
    public dialog: MatDialog, public auth: AuthService){

  }

  ngOnInit(): void {
    
    this.chargeInfo();
  }

  logout(){
    this.auth.logout();
  }

  ngAfterViewInit(): void {
    this.chargeInfo();
  }

  chargeInfo(){
    this.user = new User();
    let mail = localStorage.getItem("mailBays")
    console.log(mail,"aqui esta este mail")
    if(mail){
      this.auth.find(mail).subscribe(data=>{
        this.user = data;
      },error=>{
        
          if(error.error.message && error.error.message != "No value present"){
              
            this.messageHanlder.errorHandler(error);
          }
       
      })

      if(this.setTimeOut){
        window.clearTimeout(this.setTimeOut);
      }
    }else{
      this.setTimeOut = setTimeout(() => {
        this.chargeInfo();
      }, 3000);
    }
    
  }

  

  goToSismoit(){
    window.open("https://gecolsa.com/");
  }


  onFileWithDataComplete(data ){
    console.log(data);
   
    this.showDialog();
    this.workOrderService.saveFile(data).subscribe(data=>{
        this.messageHanlder.commonMessage("Archivo guardado correctamente","success")
        this.closeDialog()
    },error=>{
      this.closeDialog()
     
      if(error.error.message){
        this.messageHanlder.commonMessage(error.error.message,"error","Error");
      }else{
        this.messageHanlder.commonMessage("El archivo se está terminando de cargar","info");
      }
      
        
    })
  }

  canIUploadFile(){
    return this.user && this.user.email && this.user.email =='superadmin@gecolsa.com'
  }


  showDialog(){
       this.dialogRef = this.dialog.open(LoadingComponent, { width: '30%',disableClose: true })
  }

  closeDialog(){
    this.dialog.closeAll();
  }

}
