
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'
import { element } from 'protractor';
import { JWT } from './../model/jwt';
import { MessageService } from './message.service';
import { UserComponent } from './../user/user.component';
import { Router } from '@angular/router';

import { User } from './../model/user';
import { Page } from './../model/page';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  path = "auth";
  user: User;

  constructor(protected http: HttpClient,
    private router: Router, private messageHandler: MessageService) { }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user = user;
  }

  public isAuthenticatedWithout(): boolean {
    const token = localStorage.getItem('tokenBays');
    
    if (!token || token === '') {
      return false;
    }
    return true;
  }


  public isAuthPromise(comingUrl): Promise<boolean>{
    const token = localStorage.getItem('tokenBays');
    if (!token || token === '') {
      return Promise.resolve(false);
    }

    let userMail = localStorage.getItem("mailBays");
    if(userMail) {
      this.doIhaveAccess({url:comingUrl}).subscribe(data => {
        console.log(data)
       return Promise.resolve(true);;

      }, error => {
        this.messageHandler.errorHandler(error);
        return Promise.resolve(false);;
      })

    } else {
      this.messageHandler.commonMessage("No se encuentra un usuario en la sesión", "info", "Sin autenticar");
      return Promise.resolve(false);;
    }

  }

  public isAuthenticated(comingUrl): boolean {
    const token = localStorage.getItem('tokenBays');
    if (!token || token === '') {
      return false;
    }

    /* if (userMail) {
       this.doIhaveAccess({url:comingUrl}).subscribe(data => {
 
        return true;
 
       }, error => {
         this.messageHandler.errorHandler(error);
         return false;
       })
 
     } else {
       this.messageHandler.commonMessage("No se encuentra un usuario en la sesión", "info", "Sin autenticar");
       return false;
     }
   
    */

    let result = false


    if (this.user) {
    
      this.user.role.urls.forEach(element => {

        if (element.url == comingUrl) {
          result = true;
          
        }

      });

    }else{
      let email = localStorage.getItem("mailBays");
      if(email && email != ""){
        this.find(email).subscribe(data=>{
            this.user= data;
            
        },error=>{
          this.messageHandler.commonMessage("Debe iniciar sesión de nuevo","error","Lo sentimos")
        })
      }
     
    }


    return result;

  }

  find(email) {
    console.log("email",email)
    return this.http.get<User>(environment.url + 'auth/id/' + email);
  }

  doIhaveAccess(url) {
    return this.http.post(environment.url + this.path + '/access/permission', url);
  }




  getRoles() {
    return this.http.get<any>(environment.url + this.path + "/roles");
  }

  signup(request) {
    return this.http.post<any>(environment.url + this.path + "/signup", request)
  }


  list(dto) {
    return this.http.post<Page<User>>(environment.url + this.path + "/list", dto);
  }

  signin(request) {
    return this.http.post<JWT>(environment.url + 'auth/signin', request);
  }


  changePassword(email, password, oldPassowrd){
    return this.http.get(environment.url+this.path+"/change/password/"+password+"/"+email+"/"+oldPassowrd);
  }


  logout() {
    Swal.fire(
      {
        title: "¿Cerrar sesión?",
        text: "Cerrará la sesión de shop bay",
        icon: 'info',
        cancelButtonText: "No",
        confirmButtonText: "Si",
        showCancelButton: true,
        
        
      }
    ).then(accepted => {
      console.log(accepted)
      if (accepted.value && accepted.value == true) {
        this.forceLogOut();

      } else{
        this.router.navigate(["home"])
      }
    })

  }


  forceLogOut(){
    localStorage.removeItem('tokenBays');
    localStorage.removeItem("loggedBays")
    localStorage.removeItem('passwordBays')
    localStorage.removeItem("rememberBays")
    localStorage.removeItem('mailBays')
    localStorage.removeItem("userIdBays")
    localStorage.removeItem("refreshBay")
    
   
    this.router.navigate(['login']);
  }


}
