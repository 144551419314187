import { DecimalPipe } from '@angular/common';
import { BarInfoGraphic } from './../interface/bar-dialog-data';
import { MatDialogRef } from '@angular/material/dialog';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Component, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-bar-graphic-modal',
  templateUrl: './bar-graphic-modal.component.html',
  styleUrls: ['./bar-graphic-modal.component.css']
})
export class BarGraphicModalComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<BarGraphicModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private decimalPipe: DecimalPipe) {
 
      this.barChartData = this.data.data;

      this.barChartData.forEach(elem =>{
        let array = [];
        elem.data.forEach(item=>{
         
          item = decimalPipe.transform(item, '1.2-2')
          array.push(item)
        })
        elem.data = array;
      })
    
      this.barChartLabels = this.data.labels;
  }

  onNoClick(): void {
    this.dialogRef.close();


  }

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];

  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  ngOnInit(): void {

  }

  


}
