import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Page } from '../model/page';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {
  
  
  path;

  constructor(protected http: HttpClient) { }

  save(item:T){
    console.log(this.path)
    return this.http.post<T>(environment.url+this.path+'/save',item);
  }

  list(listDto:any){
    return this.http.post<Page<T>>(environment.url+this.path+'/list',listDto);
  }

  delete(item:T){
   
    return this.http.post(environment.url+this.path+'/delete',item);
  }

  find(item:T){
    return this.http.post(environment.url+this.path+'/find/id',item);
  }
}
