import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/service/base.service';
import { Activity } from '../model/activity';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends BaseService<Activity> {

  constructor(http: HttpClient) {
    super(http);
    this.path = "activity"
   }


   createFile(){

    return this.http.get(environment.url+this.path+"/download/",{responseType : 'blob'});
    //return this.http.get<any>(environment.apiUrl+this.path+"/reportAudios/"+tripId,headers);
  }
}
