import { element } from 'protractor';
import { User } from './../model/user';
import { GarageService } from './../../dash-board/service/garage.service';
import { MessageService } from './../service/message.service';
import { Component, OnInit } from '@angular/core';
import { Role } from '../model/role'
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  user: User;
  roles: Array<Role>
  garages;

  users: Array<User>

  dataSource;

  displayedColumns: string[] = ['id', 'ident', 'name', 'role', 'garage', 'edit'];

  constructor(private authService: AuthService, private messageHandler: MessageService,
    private garageService: GarageService) { }

  ngOnInit() {
    this.user = new User();
    this.roles = new Array<Role>();
    this.users = new Array<User>();
    this.dataSource = new Array<User>();
    this.getRoles();
    this.listGarages();
    this.list();

  }


  getRoles() {
    this.authService.getRoles().subscribe(data => {

      this.roles = data;
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  signUp() {
    let msn = this.valid();
    if ("" == msn) {
      this.authService.signup(this.user).subscribe(data => {
        this.clear();
        this.list();
        this.messageHandler.commonMessage("Usuario registrado con exito", "success", "Registro completo");
      }, error => {
        this.messageHandler.errorHandler(error);
      })
    } else {
      this.messageHandler.commonMessage(msn, "info", "Información erronea")
    }


  }

  valid() {
    let msn = "";
    if (this.user) {

      if (!this.user.name)
        msn += "Debe especificar un nombre de usuario;";

      if (!this.user.email) {
        msn += "Debe especificar un correo electronico;"
      }

      if (!this.user.role) {
        msn += "Debe especificar un rol;";
      }

      if (!this.user.garage) {
        msn += "Debe especificar un taller";
      }



    } else {
      msn += "Usuario no definido";
    }

    return msn;
  }

  listGarages() {

    this.garageService.list({ page: 0, size: 3000 }).subscribe(data => {
      if (data) {
        this.garages = data.content
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  clear() {
    this.user = new User();
  }


  list() {
    this.authService.list({ page: 0, size: 3000 }).subscribe(data => {
      if (data) {
        this.users = data.content;
        this.buildData();
      } else {
        this.users = [];
      }
    }, err => {
      this.messageHandler.errorHandler(err);
    })
  }

  buildData() {
    let dataSource: Array<UserInterface> = new Array<UserInterface>();
    let data;
    this.users.forEach(element => {
      console.log(element,"aqui");
      let garageName = (element.garage)?element.garage:{name:"No presenta"};
      data = {
        id : element.id,
        name : element.name,
        email : element.email,
        password : element.password,
        role  :element.role,
        userIdentification : element.userIdentification,
        attemps : element.attemps,
        lastChangePaswword : element.lastChangePaswword,
        apps : element.apps,
        garage: garageName,
        forceChangePassword:element.forceChangePassword
        
        
      }
      dataSource.push(data);
    })
    this.dataSource = dataSource;

  }

  edit(element) {
    console.log(element);
    this.user = element;
  }


}


export interface UserInterface {
  id;
  name;
  email;
  password;
  role;
  userIdentification;
  attemps;
  lastChangePaswword;
  apps;
  garage;
  forceChangePassword;
}

