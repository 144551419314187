import { messages } from './../../../environments/environment.prod';
import { LoadingComponent } from './../../core/loading/loading.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { WorkorderService } from '../service/workorder.service';
import { MessageService } from 'src/app/core/service/message.service';
import { WorkOrder } from 'src/app/core/model/workorder';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ListDTO } from 'src/app/core/model/listDTO';
import { GarageService } from '../service/garage.service';
import { BayService } from '../service/bay.service';
import { ActivityState } from '../model/activityState';
import { ComponentModel } from '../model/componentModel';
import { ActivityService } from '../service/activity.service';
import { ActivityStateService } from '../service/activity-state.service';
import { ComponentService } from '../service/component.service';
import { Bay } from '../model/bay';
import { Activity } from '../model/activity';

@Component({
  selector: 'app-traffic-light',
  templateUrl: './traffic-light.component.html',
  styleUrls: ['./traffic-light.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class TrafficLightComponent implements OnInit {

  workOrders: Array<WorkOrder>
  dataSource;

  workOrerSelected;

  garages;
  garageSelected;
  bays;
  baySelected;
  componentSelected;

  states: Array<ActivityState>;
  components: Array<ComponentModel>;
  stateSelected

  activity: Activity;
  columnsToDisplay = ['Id', 'Taller', 'OT', 'Cliente', 'F. Inicio', 'F. Final', 'Ver'];

  @ViewChild('btnCloseModal', { read: ElementRef, static: false }) set inputTag2(input: any) {
    if (!input) {
      return
    } else {
      this.btnCloseModal = input;
    }
  }

  btnCloseModal;
  dialogRef;

  onFilter;
  tittlesFilter = ['TALLER', 'STORE', 'OT', 'MODELO', 'SERIAL', 'CLIENTE', 'TIPO DE CLIENTE', 'CODIGO', 'CONTROL DE TAREA', 'PROPIETARIO', 'EN PROCESO'];
  attributesFilter = ["dto.zona", "dto.store", "dto.ot", "dto.model", "dto.serial", "dto.customer", "dto.custType", "dto.codigo", "dto.jobCtrl", 'owner', 'deleted'];
  typeFilters = ['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'deleted'];

  constructor(private workOrderService: WorkorderService, private messageHandler: MessageService,
    private garageService: GarageService, private bayService: BayService, private activityService: ActivityService,
    private componentService: ComponentService, private stateService: ActivityStateService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.listWorkOrder();

    this.onFilter = new ListDTO(new WorkOrder());
    this.onFilter.deleted = false;
    this.listGarage();

    this.bays = new Array<Bay>();
    this.states = new Array<ActivityState>();
    this.components = new Array<ComponentModel>();
    this.listComponents();
    this.listStates();
    this.listBaysByUser();
    this.activity = new Activity();
  }

  listWorkOrder(dto = { page: 0, size: 80 }) {
    this.showDialog();
    this.workOrderService.list(dto).subscribe(data => {
      this.closeDialog();
      if (data) {
        this.workOrders = data.content;
        this.constructDataSource();
      }
      if(!this.workOrders || this.workOrders.length == 0){
        this.messageHandler.commonMessage("No se encuentra ningun resultado","info")
      }
      
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  listGarage() {
    this.garageService.list({ page: 0, size: 300 }).subscribe(data => {
      console.log(data, "garages");
      if (data) {
        this.garages = data.content
      } else {
        this.garages = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }




  listBaysByUser() {
    this.bayService.getBaysByUser().subscribe(data => {
      if (data) {

        this.bays = data
      } else {
        this.bays = [];
      }
    })
  }



  constructDataSource() {

    this.dataSource = this.workOrders;


  }


  getValue(column, element) {
    if (column == "Id")
      return element['id']

    if (column == 'Taller')
      return element['zona']

    if (column == "OT")
      return element['ot']

    if (column == "Cliente")
      return element['customer']

    if (column == "F. Inicio")
      return element['openDate']

    if (column == "F. Final")
      return element['dateFinsh']

    if (column == 'Ver') {
      // return '<i class="fas fa-plus"></i>'
      return '+';
    }



  }

  expandedElement: WorkOrderInfo | null;


  filter(event) {
    this.listWorkOrder(event.principal);
    this.onFilter = new ListDTO(new WorkOrder());
  }

  selectWorkOrder(element) {
    this.garageSelected = null;
    this.baySelected = null;
    this.workOrerSelected = element;
    console.log(element, "seleccionado");
  }


  assignOTtoWorkOrder() {
    let valid = this.validAssign();

    console.log(this.baySelected, this.workOrerSelected, this.garageSelected, valid)

    if ("" == valid) {


      this.workOrderService.assign({ workOrder: this.workOrerSelected, bay: this.baySelected, state: this.stateSelected, component: this.componentSelected, activity: this.activity }).subscribe(data => {
        if (data) {
          this.btnCloseModal.nativeElement.click();
          this.messageHandler.commonMessage("La OT " + data.ot + " fue asignada correctamente", "info");
        }
      }, error => {
        this.messageHandler.errorHandler(error);
      })
    } else {
      this.messageHandler.commonMessage(valid, "error");
    }

  }




  validAssign() {
    let valid = ""

    if (!this.baySelected || !this.baySelected.id)
      valid += "Debe especificar una bahía;"


    if (!this.workOrerSelected || !this.workOrerSelected.id)
      valid += "Debe seleccionar una actividad;"

    if (!this.stateSelected || !this.stateSelected.id)
      valid += "Debe especificar un estado para la actividad";



    return valid;
  }


  listComponents() {
    this.componentService.list({ page: 0, size: 3000 }).subscribe(data => {
      console.log(data)
      if (data) {
        this.components = data.content;
      } else {
        this.components = [];
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  listStates() {
    this.stateService.list({ page: 0, size: 300 }).subscribe(data => {
      console.log(data, "states");
      if (data) {
        this.states = data.content;
      } else {
        this.states = []
      }
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  getSate(item: Bay) {
    if (item.stateString) {
      return item.stateString;
    } else {
      return item.bayState;
    }
  }

  showDialog() {
    this.dialogRef = this.dialog.open(LoadingComponent, { width: '30%', disableClose: true, data:{message:"Espere mientras se carga la información"}})
  }

  closeDialog() {
    this.dialog.closeAll();
  }


}



export interface WorkOrderInfo {
  id;
  zona;
  ot;
  model;
  serial;
  customer;
  custType;
  codigo;
  wipParts;
  wipLabor;
  wipMisc;
  wip;
  openDate;
  promDate;
  openDays;
  jobCtrl;
  ownner;
  dateFinsh;
  observs;
}

