import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {
  HttpClient, HttpResponse, HttpRequest,
  HttpEventType, HttpErrorResponse
} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { FileUploadModel } from '../model/file-upload-model';
import { UploadTask } from '@angular/fire/storage/interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-material-file-upload',
  templateUrl: './material-file-upload.component.html',
  styleUrls: ['./material-file-upload.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MaterialFileUploadComponent implements OnInit {
  /** Link text */
  @Input() text = 'Cargar';
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = 'file';
  /** Target URL for file uploading. */
  @Input() target = 'https://file.io';
  /** File extension that accepted, same as 'accept' of <input type="file" />. 
      By the default, it's set to 'image/*'. */
  @Input() accept = 'image/*';
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<any>();


  @Input() formControlName;

  private files: Array<FileUploadModel> = [];

  constructor(private _http: HttpClient) { }

  ngOnInit() {
  }

  onClick() {
    console.log(this.accept)
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {



      const file = fileUpload.files[0];
      this.files.push({
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      });

      this.uploadFiles();
    };
    fileUpload.click();
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }

  private uploadFile(file: FileUploadModel) {

    
    let strin = file.data;
    
   // let task: UploadTask = this.angularFire.tareaCloudStorage(file.data.name,file.data);

    let returned = this.isImgForUpload(file.data);

    console.log(returned)
      
    
      console.log("error")
      this.complete.emit(file.data)
      this.files = [];
    
   
    

  }


  isImgForUpload(file){

    let fileType = ['csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    let returnd = false;
    fileType.forEach(element =>{
      console.log(file.type)
      if(file.type == element){
        console.log(file.type,"aqui",element, false)
        console.log(returnd,"wowo")
        return returnd;
      }
    })

    return true;
  }

  private uploadFiles() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';

    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }
}
