export class ListDTO<T>{

    page: number;
    size: number;
    sort: string;
    deleted: boolean;
    assigned;
    dto: T;


    constructor(element: T){
        this.page = 0;
        this.size = 3000;
    
        this.dto = element;
    }




}