import { MessageService } from 'src/app/core/service/message.service';
import { state } from '@angular/animations';

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private messageHandler: MessageService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {



      if (!this.auth.isAuthenticatedWithout()) {
        this.router.navigate(['login']);
        return false;
      }
      return true;

  /*      return true;


    return new Promise((resolve, reject) => {
      this.auth.isAuthPromise(state.url.replace("/", "")).then(data => {
        console.log("resuelto",data)
        resolve(true);
      }).catch(error => {
        console.log(error);
        resolve(false);
      });
    })

    /return 
   

   let result = this.auth.isAuthenticated(state.url.replace("/",""))
    console.log("state",state.url,result)
    if(result == false) {
 
      this.messageHandler.commonMessage("no esta autorizado para acceder a este recurso","info","Lo sentimos ")
      let token =      localStorage.getItem("token")
      if(token){
        this.router.navigate(['home'])
      }else{
        console.log("aqui cerrando")
        this.router.navigate(['login']);
      }
      
      
      return false;
      
    }else{
      console.log(result,"auth")
  
    return true;
    }*/



  }

}
