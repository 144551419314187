import { DecimalPipe } from '@angular/common';
import { BarInfoGraphic } from './../interface/bar-dialog-data';
import { BarGraphicModalComponent } from './../bar-graphic-modal/bar-graphic-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-bar-graphics-modal',
  templateUrl: './bar-graphics-modal.component.html',
  styleUrls: ['./bar-graphics-modal.component.css']
})
export class BarGraphicsModalComponent implements OnInit {

  bars
  legendOption;
  constructor(
    public dialogRef: MatDialogRef<BarGraphicModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private decimalPipe: DecimalPipe) {
    this.bars = data.data;

    this.bars.forEach(bar => {
      bar.barChartData.forEach(elem => {
        let array = [];
        elem.data.forEach(item => {

          item = decimalPipe.transform(item, '1.2-2')
          array.push(item)
        })
        elem.data = array;
      })
    });
    this.legendOption = data.legendOption;

  }

  onNoClick(): void {
    this.dialogRef.close();


  }

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];

  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  ngOnInit(): void {
  }

}
