import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private router: Router) { }



  ngOnInit() {
      let item = localStorage.getItem("refreshBay")
      if(!item){
        localStorage.setItem("refreshBay","no");
        window.location.reload();
      }
      
  }

}
