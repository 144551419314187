import { Component, OnInit, Input } from '@angular/core';

import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-cake-graphic',
  templateUrl: './cake-graphic.component.html',
  styleUrls: ['./cake-graphic.component.css']
})
export class CakeGraphicComponent implements OnInit {



  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        boxWidth: 12,
        padding: 8,
        fontSize: 12,

      },


    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach(element =>{
            sum += element;
          })
          
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
          //const label = ctx.chart.data.labels[ctx.dataIndex];
          //return label;
        },
      },
    }


  };
  @Input() pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  @Input() pieChartData:

    number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  @Input() pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  constructor() { }

  ngOnInit() {


  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }




}



