import { BarGraphicsModalComponent } from './../../core/bar-graphics-modal/bar-graphics-modal.component';
import { element } from 'protractor';
import { BarGraphicModalComponent } from './../../core/bar-graphic-modal/bar-graphic-modal.component';
import { IndicatorResult } from './../model/indicator-result';
import { JustInfoDialogComponent } from './../../core/just-info-dialog/just-info-dialog.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Garage } from '../model/garage';
import { MessageService } from 'src/app/core/service/message.service';
import { GarageService } from '../service/garage.service';
import { IndicatorService } from '../service/indicator.service';
import { formatDate, DecimalPipe } from '@angular/common';
import { Indicator } from '../model/indicator';

@Component({
  selector: 'app-indicador-speed',
  templateUrl: './indicador-speed.component.html',
  styleUrls: ['./indicador-speed.component.css']
})
export class IndicadorSpeedComponent implements OnInit {



  showCake = false;
  generalGarage: Garage;

  @ViewChild('sidebarCollapse', { read: ElementRef, static: false }) set inputTag2(input: any) {
    if (!input) {
      return
    } else {
      this.btnHamburger = input;
    }
  }
  btnHamburger;

  indicators: Array<IndicatorResult>;

  garages: Array<Garage>;
  garageSelected;

  indicatorsData;

  constructor(private garageService: GarageService, private messageHandler: MessageService,
    private indicatorService: IndicatorService, public dialog: MatDialog, private decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.indicators = new Array<IndicatorResult>();
    this.listGarage()
    this.listIndicators();
    this.generalGarage = new Garage();
    this.generalGarage.name = "sin"
    this.garageSelected = new Garage();
    this.garageSelected.name = "sin"
  }


  listGarage() {
    this.garageService.list({ page: 0, size: 300 }).subscribe(data => {

      if (data) {
        this.garages = data.content
      } else {
        this.garages = [];
      }

    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }







  listIndicators() {
    this.indicatorService.getList({ page: 0, size: 3000 }).subscribe(data => {
       
      if (data) {
        this.indicatorsData = data.content;

      }
      //this.listIndicatorsByGarage();
      this.listIndicatorsByGarageAll();
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  listIndicatorsByGarageAll() {

    this.indicators = [];
    this.arrayBarsGraphics = [];

    let date = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    date += ' 00:00:00'


    this.indicatorService.getAllIndicators({ date: date, garage: this.garageSelected, indicators: this.indicatorsData }).subscribe(data => {
      console.log("estos son",data)

      this.putIndicatorAndGraphic(data);

    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }

  indicatorComponentMostUsed;
  putIndicatorAndGraphic(data) {
    console.log(data,"asi fue el resultado")
    data.forEach(element => {

      if (element.typeIndicator == "SPEEDOMETER") {
        console.log("Asi llegan desde afuera")
        this.indicators.push(element)
      } else if (element.typeIndicator == "CAKE") {
        if (element.description.indexOf("cliente") != -1) {
         
          if(element.response.length > 0){
            this.putResultCustomerCake(element.response);
          }else{
            this.errorShowCake = true;
          }
           
          
         
        } else if (element.description.indexOf("bahia") != -1) {
          this.putResultBayCake(element.response);
        }
      } else if (element.typeIndicator == "BAR") {
        if (element.description.indexOf("cliente más representativo") != -1) {
         
          this.putBarResults(element)
        } else {
          this.putBarResults(element);
        }

      }
    });
  }

 

  putResultBayCake(array) {
    this.resultsCakeBay = new Array<number>();
    this.resultsLabelsBay = new Array<string>();
    let arrayColors: Array<string> = new Array<string>();
    array.forEach(element => {
      this.resultsCakeBay.push(element.counter)
      if (element.stateString) {

        this.resultsLabelsBay.push(element.stateString);
        arrayColors.push("#" + element.color)
      } else {
        this.resultsLabelsBay.push("LIBRE");
        arrayColors.push("#08cc40")
      }



    });

    this.colorsBays = [{
      backgroundColor: arrayColors
    }]
    this.showCake = true;
    this.errorShowCakeBay = false;

  }

  putResultCustomerCake(array) {
    this.resultsCake = new Array<number>();
    this.resultsLabels = new Array<string>();
    let accumClients = 0;
    array.forEach(element => {

      if (element.custType == 'X' || element.custType == 'x') {
        this.resultsCake.push(element.count)
        this.resultsLabels.push('CLIENTES EXTERNOS');
      } else {
        accumClients += element.count;
      }

    });
    this.resultsCake.push(accumClients)
    this.resultsLabels.push('CLIENTES INTERNOS  ');

    
    this.showCake = true;
    this.errorShowCake = false;
  }


  resultsCake: Array<number>;
  resultsLabels: Array<string>;
  colorsCake = [
    {
      backgroundColor: ['rgba(191, 83, 77, 1)', 'rgba(76, 131, 187, 1)', 'rgba(134, 132, 164, 1)',
        'rgba(221, 226, 237, 1)', 'rgba(71, 196, 106, 1)', 'rgba(242, 245, 54, 1)',
        'rgba(234, 237, 50, 1)', 'rgba(44, 222, 213, 1)'],
    },
  ];


  errorShowCake = false
  errorShowCakeBay = false;


  resultsCakeBay;
  resultsLabelsBay;
  colorsBays = [
    {
      backgroundColor: ["#fff"],
    },
  ];


  generateHistoryReport() {
    this.indicatorService.generateHistoryReport().subscribe(data => {
      this.messageHandler.commonMessage("Histórico generado con éxito", "success", "");
    }, error => {
      this.messageHandler.errorHandler(error);
    })
  }


  showInfoOnDialog(indicator: IndicatorResult) {
    let tittle = "Descripción";
    if(indicator.name){
      tittle += " del "+indicator.name
    }
    const DialogRef = this.dialog.open(JustInfoDialogComponent, {
      width: '80%',
      data: { tittle: tittle, info: indicator.description }
    })
  }

  showTendencyDialog(indicator) {
    let list = new Array<Indicator>();

    let indicatorMonthly = new Indicator();
    indicatorMonthly.graficType = "MONTHLYBAR";
    indicatorMonthly.indicatorProcedure = indicator.monthly;
    indicatorMonthly.indicatorName = indicator.response[0].name;

    if (this.garageSelected) {
      indicatorMonthly.indicatorZone = this.garageSelected.id
    }

    list.push(indicatorMonthly);
    this.indicatorService.getIndicatorsMonthly(list).subscribe(data => {
      let labels = new Array<String>();
      let dataRecive = new Array<number>();
      if (data[0].response) {
        data[0].response.forEach(element => {
          labels.push(element.monthString);
          dataRecive.push(element.avg);

        });
      }

      const DialogTendencyRef = this.dialog.open(BarGraphicModalComponent, {
        width: '70%',
        data: { labels: labels, data: [{ data: dataRecive, label: "Mensual" }] , title: indicator.title }
      })

    }, error => {
      this.messageHandler.errorHandler(error);
    })

  }

  

	
  months = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
		
  showHistoryDialog(indicator) {
    console.log(indicator,"indicador recorrido");
    let list = new Array<Indicator>();
    for (let i = 1; i <= 12; i++) {
      let indicatorMonthly = new Indicator();
      indicatorMonthly.graficType = "BAR";
      indicatorMonthly.indicatorProcedure = indicator.procedure;
      indicatorMonthly.period = i + ""
      indicatorMonthly.indicatorClassImplementation = indicator.classImplementation
      indicatorMonthly.monthly = this.months[i-1];
      if (this.garageSelected) {
        indicatorMonthly.indicatorZone = this.garageSelected.id
      }

      list.push(indicatorMonthly);

    }

 

    this.indicatorService.getIndicatorsMonthly(list).subscribe(data => {
    
      let bars = new Array<any>();
      data.forEach(responseIndicator => {
        let resultsLabels = new Array<string>();
        let dataResult = new Array<number>();
        responseIndicator.response.forEach(listBars => {
          if(listBars.componentName){
            resultsLabels.push(listBars.componentName)
            dataResult.push(listBars.totalComponents);
          }else if  (listBars.customer){
            resultsLabels.push(listBars.customer)
            dataResult.push(listBars.totalWip);
          }
        
          
        });

        if(dataResult.length > 0){
          let builded = { barChartLabels: resultsLabels, barChartData: [{ data: dataResult, label: responseIndicator.monthly }] };
          bars.push(builded);
  
        }
       
      });

      const DialogTendencyRef = this.dialog.open(BarGraphicsModalComponent, {
        width: '80%',
        data: { data: bars, legendOption: {align:'center', position:'top'}, title: indicator.title},
        maxHeight:'90vh'
      })


    }, error => {
      
      this.messageHandler.errorHandler(error);
    })

  }

  
  legendOptions= {position: 'bottom', align:"center"}

  arrayBarsGraphics = [];
  putBarResults(indicator){
     let resultsLabelsIndicator = new Array<String>();

    let dataResult = new Array<number>();

    if(indicator.response.length > 0){
      indicator.response.forEach(responseResult => {
        if(responseResult.componentName){
          resultsLabelsIndicator.push(responseResult.componentName)
          dataResult.push(responseResult.totalComponents);
        }else if  (responseResult.customer){
          resultsLabelsIndicator.push(responseResult.customer)
          dataResult.push(Number(this.decimalPipe.transform(responseResult.totalWip,'1.2-2')));
        }
       
      });
  
      let buildedInfo = [{data:dataResult, label:indicator.procedureName}];
      let itemArrayBar = {labels: resultsLabelsIndicator, info: buildedInfo, indicator: indicator}
      this.arrayBarsGraphics.push(itemArrayBar);
      
    }else{
      let buildedInfo = [{data:dataResult, label:indicator.procedureName}];
      let itemArrayBar = {error : "El indicador "+indicator.procedureName+" No contiene información ",info: buildedInfo, indicator: indicator}
      this.arrayBarsGraphics.push(itemArrayBar);
    }
   
    
  }

 

}

