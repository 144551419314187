import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/service/base.service';
import { HttpClient } from '@angular/common/http';
import { ComponentModel } from '../model/componentModel';

@Injectable({
  providedIn: 'root'
})
export class ComponentService  extends BaseService<ComponentModel>{

  constructor(http: HttpClient) {
    super(http);
    this.path = "component";
  }
}
