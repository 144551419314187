import { HttpInteceptor } from './core/http-interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashBoardModule } from './dash-board/dash-board.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireStorage } from 'angularfire2/storage';
import { CoreModule } from './core/core.module';
import { GraphproofComponent } from './graphproof/graphproof.component';


@NgModule({
  declarations: [
    AppComponent,
    GraphproofComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DashBoardModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    CoreModule
    
  ],
  providers: [{
    provide: AngularFireStorage
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInteceptor,
    multi:true 
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
