import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-speedometer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './speedometer.component.html',
  styleUrls: ['./speedometer.component.css']
})
export class SpeedometerComponent implements OnInit {


  public canvasWidth = 300
  @Input() needleValue = 65
  public centralLabel = ''
  @Input() name = 'Nombre Velocimetro'
  @Input() bottomLabel = '65'
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 3000,
    arcColors: null,
    arcDelimiters: null,
    rangeLabel: ['0%', '100%'],
    needleStartValue: 0,
  }

  @Input() arcColor = ['rgb(255, 0, 0)','rgb(238,255,0)', 'rgb(0,255,30)'] ;
  @Input() arcDelimiter = [30,80];

  private chartContainer: ElementRef;

  constructor() { }

  ngOnInit() {
    this.options.arcColors = this.arcColor;
    this.options.arcDelimiters = this.arcDelimiter;
    this.bottomLabel = this.bottomLabel+"%";
  }

}
