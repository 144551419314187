import { UserDateAudit } from 'src/app/core/model/user-date-audit';

export class Bay extends UserDateAudit{

    id;
	garageId;
	garageName;
	bayState;
	bayNumber;
	style;
	stateString;
	color;
	excludeBay;
	workorders;
	activities;
	name;
     
}