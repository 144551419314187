import { BarGraphicComponent } from './core/bar-graphic/bar-graphic.component';
import { ForceChangePasswordComponent } from './core/force-change-password/force-change-password.component';
import { HomeComponent } from './core/home/home.component';
import { AuthGuard } from './core/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BayComponent } from './dash-board/bay/bay.component';
import { ActivityComponent } from './dash-board/activity/activity.component';
import { LayoutComponent } from './dash-board/layout/layout.component';
import { PieceComponent } from './dash-board/piece/piece.component';
import { StateComponent } from './dash-board/state/state.component';
import { LoginComponent } from './core/login/login.component';
import { TrafficLightComponent } from './dash-board/traffic-light/traffic-light.component';
import { SpeedometerComponent } from './core/speedometer/speedometer.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { IndicadorSpeedComponent } from './dash-board/indicador-speed/indicador-speed.component';
import { NumberIndicatorsComponent } from './dash-board/number-indicators/number-indicators.component';
import { CakeGraphicComponent } from './core/cake-graphic/cake-graphic.component';
import { UserComponent } from './core/user/user.component';


export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard] 

  },
  {
    path: 'login',
    component: LoginComponent

  },
  {
    path: 'bay',
    component: BayComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'layout',
    component: LayoutComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'component',
    component: PieceComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'state',
    component: StateComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'traffic/light',
    component: TrafficLightComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'graphics',
    component: IndicadorSpeedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'indicators',
    component: NumberIndicatorsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change/password',
    component: ForceChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bar',
    component: BarGraphicComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
