import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/core/model/page';
import { Indicator } from '../model/indicator';
import { WorkOrder } from 'src/app/core/model/workorder';

@Injectable({
  providedIn: 'root'
})
export class IndicatorService {

  path = "indicator"
  constructor( protected http: HttpClient) { }


  getProductivityDay(adapter){
    return this.http.post<any>(environment.url+this.path+"/image",adapter);
  }

  getList(dto){
    return this.http.post<Page<Indicator>>(environment.url+this.path+"/list",dto);
  }

  getCake(garageName){
    return this.http.get<any>(environment.url+this.path+"/cake/"+garageName);
  }

  getCakeBay(garageName){
    return this.http.get<any>(environment.url+this.path+"/cake/bays/"+garageName);
  }

  generateHistoryReport(){
    return this.http.get(environment.url+this.path+"/history/report");
  }

  getAllIndicators(adapter){
    return this.http.post<any>(environment.url+this.path+"/image/all",adapter);
  }

  getIndicatorsMonthly(list){
    return this.http.post<any>(environment.url+this.path+"/monthly",list);
  }

}
