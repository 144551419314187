import { UserDateAudit } from 'src/app/core/model/user-date-audit';

export class Activity extends UserDateAudit{
    id;
	description;
	bayId;
	garageId;
    workOrder;
    componentCode;
    bayNumber;
    garageName;
    activityStateCode;
    color;
    panelOpenState = false;
    componentName;
    stateName;
    oldBay;
    
}