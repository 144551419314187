// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://test.sismoit.com/'
 // url: 'http://localhost:5000/'
};

export const firebaseConfig = {
   apiKey: "AIzaSyCdrgekhdUBSEYZs_vcuf27hrSwdt7ROMw",
    authDomain: "shop-bay.firebaseapp.com",
    databaseURL: "https://shop-bay.firebaseio.com",
    projectId: "shop-bay",
    storageBucket: "shop-bay.appspot.com",
    messagingSenderId: "615702782897",
    appId: "1:615702782897:web:18385d7bc4320e3569d390",
    measurementId: "G-CYEFKRX07N"
}

export const messages = {
  errorTittle:"Error",
  saveTittle:"Guardado",
  saveSuccess:"Registro guardado con éxito.",
  deleteSuccess:"Registro eliminado con éxito.",
  updateSuccess: "Registro actualizado con éxito"
}

