import { UserDateAudit } from './user-date-audit';

export class WorkOrder extends UserDateAudit{

    id;
    zona;
    ot;
    model;
    serial;
    customer;
    custType;
    codigo;
    wipParts;
    wipLabor;
    wipMisc;
      wip;
      openDate;
      promDate;
      openDays;	
      jobCtrl;	
      ownner;	
      dateFinsh;
      observs;
    
}