import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from "@angular/material/tree";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import {

  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatBadgeModule
} from "@angular/material";
import { MaterialFileUploadComponent } from './material-file-upload/material-file-upload.component';
import { firebaseConfig } from 'src/environments/environment';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { MaterialImgUploadComponent } from './material-img-upload/material-img-upload.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(firebaseConfig),
    CdkTableModule,
    //MatTreeModule,
    //MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    // MatChipsModule,
    MatStepperModule,
    //  MatDatepickerModule,
    MatDialogModule,
    //  MatDividerModule,
    MatExpansionModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    //  MatMenuModule,
    //  MatNativeDateModule,
    // MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    //  MatRadioModule,
    //  MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    //  MatSnackBarModule,
    //  MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    // DragDropModule,

  ],
  exports: [
    CdkTableModule,
    // MatTreeModule,
    // MatAutocompleteModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDividerModule,
    
    // MatGridListModule,
    // MatListModule,
    // MatMenuModule,
    //MatNativeDateModule,
    // MatPaginatorModule,
    // MatRadioModule,
    // MatRippleModule,
    // MatSidenavModule,
    //  MatSliderModule,
    // MatSnackBarModule,
    // MatSortModule,
    //DragDropModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatStepperModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,

    MaterialFileUploadComponent,
    MaterialImgUploadComponent
  ],
  declarations: [MaterialFileUploadComponent, MaterialImgUploadComponent]
})
export class MaterialModule { }
