import { Injectable } from '@angular/core';
import { UserDateAudit } from 'src/app/core/model/user-date-audit';
import { WorkOrder } from 'src/app/core/model/workorder';
import { BaseService } from 'src/app/core/service/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkorderService extends BaseService<WorkOrder>{

  constructor(http: HttpClient) {
    super(http);
    this.path = "workorder";
  }

  assign(adapter){
    return this.http.post<WorkOrder>(environment.url+this.path+"/assign",adapter);
  }


}
